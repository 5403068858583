.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Animation */
.welcome-animation {
  opacity: 0;
  transform: translateY(-50%);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.welcome-animation.show {
  opacity: 1;
  transform: translateY(0);
}


:root {
  --background-color-light: #e8eaf1;
  --background-color-dark: #e8eaf1;

  /* --background-color-dark: #313a50; */
  --text-color-light: #000000;
  --text-color-dark: #000000;
  /* --text-color-dark: #ffffff;  */
}


.footer {
  text-align: center;
  margin-top: 60px;
}

body.light-mode {
  background-color: var(--background-color-light);
  color: var(--text-color-light);
}

body.dark-mode {
  background-color: var(--background-color-light);
  color: var(--text-color-light);
  /* background-color: var(--background-color-dark);
  color: var(--text-color-dark); */
}

body.dark-mode #card{
  /* background-color: #3c4763; */
  background-color: #cbd2e0;
}

body.light-mode #card{
  background-color: #cbd2e0;
}

body.light-mode #cardhead{
  background-color: #c1c8da;
}

body.dark-mode #cardhead{
  background-color: #c1c8da;
  /* background-color: #596a93; */
}

body.dark-mode #tablehead{
  /* background-color: #596a93;
  color:"#FFFFFF", */
  background-color: #c1c8da;
  color:"black",
}

body.dark-mode #tablebody{
  /* background-color: #3b4762;
  color:"#FFFFFF", */
  background-color: #e0e4ed;
  color:"black",
}

body.light-mode #tablebody{
  background-color: #e0e4ed;
  color:"black",
}

body.light-mode #tablehead{
  background-color: #c1c8da;
  color:"black",
}

body.dark-mode .btnsc{
  /* background-color: #e8eaf1;
  color: #313a50; */
  background-color: #313a50;
  color: white;
}

body.light-mode .btnsc{
  background-color: #313a50;
  color: white;
}

body.dark-mode #sidebar{
  /* background-color: #1f2937; */
  background-color: #d8dde8
}

body.light-mode #sidebar{
  background-color: #d8dde8;
}

.siderow{
  display: flex;
}

.sidecol1{
  width:8%;
}

.sidecol2{
  width:90%;
}

.dashboard{
  font-size: 50px;
}

@media only screen and (max-width: 600px) {
  .sidecol1{
    width:19%;
  }
  
  .sidecol2{
    width:81%;
  }
  .dashboard{
    font-size: 30px;
  }
}

.backlogin{
  /* background-image: url(../public/Assets/back.png); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.table-row-odd {
  background-color: #191c24;
  color: white;
}

.table-row-even {
  background-color: black;
  color: white;
}


.sidebar-container {
  position: fixed;
  top: 0;
  right: -300px; /* Initial position */
  bottom: 0;
  width: 300px;
  transition: right 0.3s ease; /* Animation transition */
}

.sidebar-container.open {
  right: 0; /* Move sidebar to the right when it's open */
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 998; /* Ensure overlay is above sidebar */
}

.sidebar {
  height: 100%;
  background-color: #9c50af;
  z-index: 999; /* Ensure sidebar is above overlay */
  /* overflow-y: auto;  */
  border-left:10px solid #7b3098;
}

.sidebar-content {
  /* Add any additional styling for sidebar content */
}








.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  background-color: #9c50af;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
  border-left: 5px solid #7b3098;
}

.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 16px;
  color: #fff;
  display: block;
  font-weight: 'bold';
  transition: 0.3s;
}

.sidenav a:hover {
  color: #f1f1f1;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  left: 0px;
  font-size: 36px;
  margin-right: 50px;
}

@media screen and (max-height: 450px) {
  .sidenav {padding-top: 15px;}
  .sidenav a {font-size: 16px;}
}



.table-responsive::-webkit-scrollbar {
  width: 0px; /* Width of the scrollbar */
  background-color: #f0f0f0; /* Background color of the scrollbar track */
}

.table-responsive::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scrollbar thumb */
}

.table-responsive::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Color of the scrollbar thumb on hover */
}

.table-responsive::-webkit-scrollbar-thumb:active {
  background-color: #333; /* Color of the scrollbar thumb when clicked */
}

@keyframes myAnim {
  0% {
      animation-timing-function: ease-in;
      opacity: 0;
      transform: translateY(250px);
  }

  38% {
      animation-timing-function: ease-out;
      opacity: 1;
      transform: translateY(0);
  }

  55% {
      animation-timing-function: ease-in;
      transform: translateY(65px);
  }

  72% {
      animation-timing-function: ease-out;
      transform: translateY(0);
  }

  81% {
      animation-timing-function: ease-in;
      transform: translateY(28px);
  }

  90% {
      animation-timing-function: ease-out;
      transform: translateY(0);
  }

  95% {
      animation-timing-function: ease-in;
      transform: translateY(8px);
  }

  100% {
      animation-timing-function: ease-out;
      transform: translateY(0);
  }
}

